import goldCoins from '../assets/images/coins.png';

function Coins(el) {
  let exists = document.getElementById('coins');
  if (exists) {
    exists.parentNode.removeChild(exists);
    return false;
  }

  let element = document.querySelector(el);
  let canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    focused = false;

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  canvas.id = 'coins';

  let coin = new Image();
  coin.src = goldCoins;

  // 440 wide, 40 high, 10 states
  coin.onload = function () {
    if (element === null) return;
    element.appendChild(canvas);
    focused = true;
    drawloop();
  };
  let coins = [];

  function drawloop() {
    if (focused) {
      requestAnimationFrame(drawloop);
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (Math.random() < 0.3) {
      coins.push({
        x: (Math.random() * canvas.width) | 0,
        y: -50,
        dy: 3,
        s: 0.5 + Math.random(),
        state: (Math.random() * 10) | 0,
      });
    }
    let i = coins.length;

    while (i--) {
      let x = coins[i].x;
      let y = coins[i].y;
      let s = coins[i].s;
      let state = coins[i].state;
      coins[i].state = state > 9 ? 0 : state + 0.1;
      coins[i].dy += 0.3;
      coins[i].y += coins[i].dy;

      ctx.drawImage(
        coin,
        44 * Math.floor(state),
        0,
        44,
        40,
        x,
        y,
        44 * s,
        40 * s
      );

      if (y > canvas.height) {
        coins.splice(i, 1);
      }
    }
  }
}

export default Coins;
