import React from 'react';
import Award1 from '../assets/images/award-1.svg';

const LeaderCell = ({ phc, index }) => {
  const {
    pay_for_performance_score = 0,
    first_name = '',
    last_name = '',
    total_cleans = 0,
    id = 0,
    tip = 0,
    score = 0,
    achievements = [],
  } = phc;

  return (
    <div className='leadercell_container' id={id}>
      <span id={`leader-${index}`}>#{index + 1}</span>
      <span>{first_name + ' ' + last_name}</span>
      <span>{pay_for_performance_score.toFixed(2)}</span>
      <span>{total_cleans}</span>
      <span>${Math.round(tip)}</span>
      <span>
        {achievements.map((achievement,index) => {
          return <img className='achievement_image' src={require(`../assets/images/${achievement}.svg`)} key={index}></img>;
        })}
      </span>
      <span>{score}</span>
    </div>
  );
};

export default LeaderCell;
