import moment from 'moment';
import 'normalize.css';
import React, { useEffect, useRef, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import './App.scss';
import gataware from './api/gataware';
import MaidBackground from './assets/images/maid_bg.jpeg';
import splashScreen from './assets/images/splashScreen.png';
import Coins from './components/Coins';
import Header from './components/Header';
import KPIS from './components/KPIS';
import LeaderBoard from './components/LeaderBoard';
import Legend from './components/Legend';
import TopMaid from './components/TopMaid';

import MonthDropdown from './components/MonthDropdown';
import db from './firebase';

function App() {
  const [phcData, setPhcData] = useState([]);
  const [filteredPhcData, setFilteredPhcData] = useState([]);
  const [totalTips, setTotalTips] = useState(0);
  const [totalCleans, setTotalCleans] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('Just now.');
  const [currentTime, setCurrentTime] = useState(new Date());
  const test = useRef(false);
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [monthChangeLoading, setMonthChangeLoading] = useState(false);

  let i = 0;
  function move() {
    if (i == 0) {
      i = 1;
      let elem = document.getElementById('load');
      let width = 1;
      let id = setInterval(frame, 100);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          width++;
          elem.style.width = width + '%';
        }
      }
    }
  }

  const onlineUserReference = db
    .ref(`/appointments/`)
    .orderByChild('created')
    .limitToLast(1);

  useEffect(() => {
    if (loading === false) {
      onlineUserReference.on('child_added', (snapshot) => {
        const data = snapshot.val();
        const teamMemberIds = data.teamMemberIds;
        if (!test.current) {
          test.current = true;
          return;
        }
        for (const member of teamMemberIds) {
          phcData.forEach((phc, index) => {
            if (phc.id == member) {
              let newArr = [...phcData]; // copying the old datas array
              newArr[index].tip += data.tipAmount;
              setPhcData(newArr);

              // const now = moment.utc();
              // var end = moment(currentDate);
              // var minutes = now.diff(end, 'minutes');
              // setLastUpdated(`${minutes} minutes ago`);
              setCurrentTime(new Date());
              Coins('body');
              // Remove Coins
              setTimeout(() => {
                Coins(null);
              }, 10000);
            }
          });
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    move();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    gataware
      .post('/get-phc-ratings/', {
        slug: params.slug,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      })
      .then((result) => {
        let tempAllPhcs = result.data.phc_ratings_data;

        setTotalCleans(result.data.total_cleans);
        setTotalTips(result.data.total_tips);
        const phc_tip_data = result.data.phc_tip_data;
        let phcs_organized = phc_tip_data.reduce((acc, cur) => {
          acc[cur['id']] = [...(acc[cur['id']] || []), cur];
          return acc;
        }, {});

        const tempPhcData = [];

        for (const phc in phcs_organized) {
          let tipTotal = 0;
          let id = '';
          let name = '';
          for (const phc_in_team of phcs_organized[phc]) {
            tipTotal += Number(phc_in_team.tip);
            id = phc_in_team.id;
            name = `${phc_in_team.first_name} ${phc_in_team.last_name}`;
          }
          tempPhcData.push({ id, tip: tipTotal, name });
        }

        let combinedPhcData = tempAllPhcs.map((phc) => {
          for (const tempPhc of tempPhcData) {
            if (tempPhc.id === phc.id) {
              phc.tip = tempPhc.tip;
              phc.achievements = [];
              let multiplier = 1;
              if (phc.total_cleans >= 60) {
                phc.achievements.push('gold');
                multiplier = 1.3;
              } else if (phc.total_cleans >= 40 && phc.total_cleans < 60) {
                phc.achievements.push('silver');
                multiplier = 1.2;
              } else if (phc.total_cleans >= 20 && phc.total_cleans < 40) {
                phc.achievements.push('bronze');
                multiplier = 1.1;
              }
              phc.score = Math.round(
                (Math.round(phc.tip) + phc.total_cleans * 10) * multiplier
              );

              return phc;
            }
          }
        });
        combinedPhcData = combinedPhcData
          .sort((a, b) => (a.score > b.score ? 1 : -1))
          .reverse();
        let filteredPhcs = combinedPhcData.filter(
          (phc) => phc?.pay_for_performance_score > 6
        );
        console.log('filtered', filteredPhcs);
        setFilteredPhcData(filteredPhcs);

        setPhcData(combinedPhcData.filter((phc) => phc != undefined));

        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      });
  }, []);

  const handleMonthChangeView = (start, end, month) => {
    move();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setMonthChangeLoading(true);

    gataware
      .post('/get-phc-ratings/', {
        slug: params.slug,
        start_date: start,
        end_date: end,
      })
      .then((result) => {
        console.log(result.data);
        let tempAllPhcs = result.data.phc_ratings_data;
        setMonth(month);
        setTotalCleans(result.data.total_cleans);
        setTotalTips(result.data.total_tips);
        const phc_tip_data = result.data.phc_tip_data;
        let phcs_organized = phc_tip_data.reduce((acc, cur) => {
          acc[cur['id']] = [...(acc[cur['id']] || []), cur];
          return acc;
        }, {});

        const tempPhcData = [];

        for (const phc in phcs_organized) {
          let tipTotal = 0;
          let id = '';
          let name = '';
          for (const phc_in_team of phcs_organized[phc]) {
            tipTotal += Number(phc_in_team.tip);
            id = phc_in_team.id;
            name = `${phc_in_team.first_name} ${phc_in_team.last_name}`;
          }
          tempPhcData.push({ id, tip: tipTotal, name });
        }

        let combinedPhcData = tempAllPhcs.map((phc) => {
          for (const tempPhc of tempPhcData) {
            if (tempPhc.id === phc.id) {
              phc.tip = tempPhc.tip;
              phc.achievements = [];
              let multiplier = 1;
              if (phc.total_cleans >= 60) {
                phc.achievements.push('gold');
                multiplier = 1.3;
              } else if (phc.total_cleans >= 40 && phc.total_cleans < 60) {
                phc.achievements.push('silver');
                multiplier = 1.2;
              } else if (phc.total_cleans >= 20 && phc.total_cleans < 40) {
                phc.achievements.push('bronze');
                multiplier = 1.1;
              }
              phc.score = Math.round(
                (Math.round(phc.tip) + phc.total_cleans * 10) * multiplier
              );

              return phc;
            }
          }
        });
        combinedPhcData = combinedPhcData
          .sort((a, b) => (a.score > b.score ? 1 : -1))
          .reverse();
        let filteredPhcs = combinedPhcData.filter(
          (phc) => phc?.pay_for_performance_score > 6
        );
        console.log('filtered', filteredPhcs);
        setFilteredPhcData(filteredPhcs);

        setPhcData(combinedPhcData.filter((phc) => phc != undefined));

        setMonthChangeLoading(false);
      })
      .catch((e) => {
        setMonthChangeLoading(false);
        setError(true);
        console.log(e);
      });
  };
  return (
    <div className='App'>
      {loading ? (
        <div>
          {error ? (
            <div style={{ fontSize: '22px' }}>404 Error</div>
          ) : (
            <div className='splash-container'>
              <img src={splashScreen} alt='Two Maids' />
              <div id='loadingBar'>
                <div id='load'></div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <img className='demo-bg' src='https://res.cloudinary.com/twomaidsengi/image/upload/v1723660112/map-dashboard/maid_bg.jpg' alt='' />
          <Header month={month} />
          <MonthDropdown handleMonthChangeView={handleMonthChangeView} />
          {monthChangeLoading && (
            <div className='month-change-loader'>Loading...</div> // This is your loader for month change
          )}

          <p className='last-updated'>
            Last Updated: <ReactTimeAgo date={currentTime} locale='en-US' />.
          </p>
          <LeaderBoard phcData={filteredPhcData} />

          <div className='bottom_comtainer'>
            <KPIS totalTips={totalTips} totalCleans={totalCleans} />
            {/* <TopMaid topMaid={phcData[0]} /> */}
            {/* <TopMaids /> */}
            <Legend />
          </div>
          <footer>
            <p>{currentYear} © Gataware Software Suite.</p>
            <p> Two Maids Franchising, LLC. </p>
            <p>All Rights Reserved. </p>
            <p> Made with &#10084; in Birmingham, Alabama.</p>
          </footer>
          <marquee>
            {phcData.map((phc, index) => {
              if (phc === undefined) return '';
              return `#${
                index + 1
              } ${phc?.first_name.toString()} ${phc?.last_name.toString()} - ${Number(
                phc?.score.toFixed(2)
              ).toLocaleString('en')} \u00A0\u00A0\u00A0\u00A0`;
            })}
          </marquee>
        </>
      )}
    </div>
  );
}

export default App;
