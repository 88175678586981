import React, { useEffect, useState } from 'react';

const MonthDropdown = ({ handleMonthChangeView }) => {
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const currentMonth = new Date().getMonth();

  useEffect(() => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const availableMonths = monthNames.slice(0, currentMonth + 1);
    setMonths(monthNames);
  }, []);

  const getMonthStartEndDates = (monthIndex) => {
    const year = new Date().getUTCFullYear();
    const startDate = new Date(Date.UTC(year, monthIndex, 1));
    const endDate =
      monthIndex === 11
        ? new Date(Date.UTC(year + 1, 0, 1))
        : new Date(Date.UTC(year, monthIndex + 1, 1));

    // Subtract one day to get the end of the selected month
    endDate.setDate(endDate.getDate() - 1);

    return {
      start: startDate.toISOString().split('T')[0], // Returns "YYYY-MM-DD"
      end: endDate.toISOString().split('T')[0], // Returns "YYYY-MM-DD"
    };
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);

    console.log('e.target.value', e.target.value);
    const month = e.target.value;

    const monthIndex = months.indexOf(e.target.value);
    const { start, end } = getMonthStartEndDates(monthIndex);
    console.log(start, end);
    handleMonthChangeView(start, end, month);
    // Trigger API request here
  };

  return (
    <div>
      <select value={selectedMonth} onChange={handleMonthChange}>
        <option value='' disabled>
          Select a month
        </option>
        {months.map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MonthDropdown;
