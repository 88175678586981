import React from 'react';
import bronzeMedal from '../assets/images/bronze.svg';
import silverMedal from '../assets/images/silver.svg';
import goldMedal from '../assets/images/gold.svg';

const Legend = () => {
  return (
    <div className='legend_container'>
      <div className='award_base_container'>
        <div className='medal_group_container'>
          <div className='medal_container'>
            <span className="number_of_cleans">20 Cleans</span>
            <img src={bronzeMedal} alt='award' />
            <span>1.1x Points</span>
            <div className='score_explanation'>
              <div>Score = Points x 1.1</div>
            </div>
          </div>
          <div className='medal_container'>
            <span className="number_of_cleans">40 Cleans</span>
            <img src={silverMedal} alt='award' />
            <span>1.2x Points</span>
            <div className='score_explanation'>
              <div>Score = Points x 1.2</div>
            </div>
          </div>
          <div className='medal_container'>
            <span className="number_of_cleans">60 Cleans</span>
            <img src={goldMedal} alt='award' />
            <span>1.3x Points</span>
            <div className='score_explanation'>
              <div>Score = Points x 1.3</div>
            </div>
          </div>
        </div>
      </div>
      <div className='disclaimer'>$1 Tip = 1 point</div>
      <div className='disclaimer'>1 Clean = 10 points</div>
      <div className='disclaimer'>*PHCs with an average rating below 8 are disqualified</div>
    </div>
  );
};

export default Legend;
