import React from 'react';

const KPIS = ({totalTips,totalCleans}) => {
  return (
    <div className='kpi_container'>
      <h5 className='bottom_headers'>Monthly Stats</h5>
      <div className='kpi_cell'>
        <span>Cleans</span>
        <span>{totalCleans}</span>
      </div>
      <div className='kpi_cell'>
        <span>Tips</span>
        <span>${Number(totalTips).toLocaleString('en')}</span>
      </div>

    </div>
  );
};

export default KPIS;
