import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import Sparkles from '../assets/images/sparkles-two.svg';
import Logo from '../assets/images/twomaidslogo.svg';

const Header = ({month =''}) => {

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <div className='header_container'>
    <img src='https://res.cloudinary.com/twomaidsengi/image/upload/v1670257150/hubsite-reskin/two-maids-logo-white.png' alt="logo" className='logo'/>
      <h5>
        {month ?? monthNames[new Date().getMonth()]} {new Date().getFullYear()}
      </h5>
      <div className='title_container'>
        <SVG src={Sparkles} className='sparkles d-none d-md-flex' />
        {/* <img src={Ribbon} alt='ribbon' /> */}
        <h1>MAIDBOARD</h1>
        {/* <img src={Ribbon} alt='ribbon' /> */}
        <SVG src={Sparkles} className='sparkles d-none d-md-flex sparkles-right' />
      </div>
    </div>
  );
};

export default Header;
