import axios from 'axios';
const config = {
  baseURL: 'https://gataware.com/rest-api',
  // baseURL: 'http://localhost:8000/rest-api',
  // baseURL: 'https://testing.gataware.com/rest-api',
  // https://testing.gataware.com/rest-api

  headers: {
    'X-Api-Key': process.env.REACT_APP_GATAWARE_API,
    'Content-Type': 'application/json',
  },

  responseType: 'json', // default

  // timeout: 8000, // default is `0` (no timeout)

  maxRedirects: 5, // default

  validateStatus(status) {
    return status >= 200 && status < 500; // default
  },
};

const gataware = axios.create(config);

export default gataware;
