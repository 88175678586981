import React from 'react';
import LeaderCell from './LeaderCell';

const LeaderBoard = ({ phcData }) => {
  return (
    <div className='leaderboard_container'>
      <div className='title_container'>
        <span>Rank</span>
        <span>Name</span>
        <span>Avg Rating</span>
        <span>Cleans</span>
        <span>Tips</span>
        <span>Achievements</span>
        <span>Points</span>
      </div>
      {!phcData.length && (
        <div>
          <div className='leadercell_container' />
          <div className='leadercell_container' />
          <div className='leadercell_container' />
          <div className='leadercell_container' />
          <div className='leadercell_container' />
        </div>
      )}

      {phcData.map((phc, index) => {
        console.log('phc is', phc);
        if (
          index > 4 ||
          phc?.pay_for_performance_score < 8 ||
          phc?.pay_for_performance_score === undefined
        )
          return <div key={phc?.id}></div>;
        return <LeaderCell key={phc?.id} phc={phc} index={index} />;
      })}
    </div>
  );
};

export default LeaderBoard;
